import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { ReviewFrequencyDetailsDTO } from '../dto/review-frequency.dto';

@Injectable({
  providedIn: 'root',
})
export class ReviewFrequencyConversion {
  private lang: string;

  constructor(private currentUserPreferenceStore: CurrentUserPreferenceStore) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToDetailsDTO(response: any): ReviewFrequencyDetailsDTO | null {
    if (!response) {
      return null;
    }
    return {
      id: response.id,
      title: response[`title_${this.lang}`],
      type: response.type,
      status: response.status,
    };
  }
}
