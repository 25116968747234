export interface ComplianceCategoryDTO {
  id: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface ComplianceCategoryFormDTO {
  id?: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface ComplianceCategoryDetailsDTO {
  id: number;
  title: string;
  status: string;
}

export function convertResponseToComplianceCategoryDetailsDTO(
  response: any,
  lang: string
): ComplianceCategoryDetailsDTO | null {
  if (!response) {
    return null;
  }
  return {
    id: response.id,
    title: response[`title_${lang}`],
    status: response.status,
  };
}
