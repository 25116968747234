import { Injectable } from '@angular/core';
import {
  ComplianceAssociatedItemSummaryDTO,
  ComplianceFormDTO,
  ComplinaceListResponseDTO,
} from '../dto/compliance.dto';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ComplianceReviewDTO,
  ComplianceReviewFormDTO,
} from '../dto/compliance-review.dto';
import {
  ComplianceLatestStatusDTO,
  ComplianceStatusDTO,
} from '../dto/compliance-status.dto';
import { MainBaseService } from '../../../../core/services/main-base-service';

@Injectable({
  providedIn: 'root',
})
export class ComplianceService extends MainBaseService<
  ComplinaceListResponseDTO,
  ComplianceFormDTO
> {
  protected apiUrl = environment.apiUrl + '/compliance-register';
  protected complianceDetailsApiUrl = environment.apiUrl + '/compliances';

  constructor(http: HttpClient) {
    super(http);
  }

  getComplianceReviewHistory(
    complianceId: number,
    sortField: string = '',
    sortOrder: string = 'asc'
  ): Observable<ComplianceReviewDTO[]> {
    let params = new HttpParams()
      .set('sort_by', sortField)
      .set('sort_order', sortOrder);

    return this.http.get<ComplianceReviewDTO[]>(
      `${this.complianceDetailsApiUrl}/${complianceId}/reviews`,
      { params }
    );
  }

  saveComplianceReview(
    complianceId: number,
    item: ComplianceReviewFormDTO
  ): Observable<ComplianceReviewFormDTO> {
    return this.http.post<ComplianceReviewFormDTO>(
      `${this.complianceDetailsApiUrl}/${complianceId}/reviews`,
      item
    );
  }

  updateComplianceReview(
    complianceId: number,
    updateId: number,
    item: ComplianceReviewFormDTO
  ): Observable<ComplianceReviewFormDTO> {
    return this.http.put<ComplianceReviewFormDTO>(
      `${this.complianceDetailsApiUrl}/${complianceId}/reviews/${updateId}`,
      item
    );
  }

  getPdfPreview(complianceId: number, token: string): string {
    return `${this.apiUrl}/${complianceId}/document-preview?token=${token}`;
  }

  getDownloadURL(complianceId: number, token: string): string {
    return `${this.apiUrl}/${complianceId}/document-download?token=${token}`;
  }

  getComplianceAssociatedItemSummary(
    complianceId: number
  ): Observable<ComplianceAssociatedItemSummaryDTO> {
    return this.http.get<ComplianceAssociatedItemSummaryDTO>(
      `${this.complianceDetailsApiUrl}/${complianceId}/associated-item-summary`
    );
  }

  getComplianceRequirementStatusSummary(
    complianceId: number
  ): Observable<ComplianceStatusDTO[]> {
    return this.http.get<ComplianceStatusDTO[]>(
      `${this.complianceDetailsApiUrl}/${complianceId}/requirements-status-summary`
    );
  }

  getLatestComplianceStatus(
    complianceId: number
  ): Observable<ComplianceLatestStatusDTO[]> {
    return this.http.get<ComplianceLatestStatusDTO[]>(
      `${this.complianceDetailsApiUrl}/${complianceId}/status-updates/latest`
    );
  }

  getRiskHeatmap(complianceId: number): any {
    const url = `${this.complianceDetailsApiUrl}/${complianceId}/risk-heatmap`;
    return this.http.get<any>(url);
  }
}
