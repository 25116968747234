export interface ComplianceReviewStatusDTO {
    id: number;
    title_en: string;
    title_ar: string;
    color_code: string;
    type: string;
    status: string;
  }
  export interface ComplianceReviewStatusDetailsDTO {
    id: number;
    title: string;
    color_code: string;
    type: string;
    status: string;
  }
  
  export function convertResponseToComplianceReviewStatusDetailsDTO(
    response: any,
    lang: string
  ): ComplianceReviewStatusDetailsDTO {
    return {
      id: response.id,
      title: response[`title_${lang}`],
      color_code: response.color_code,
      type: response.type,
      status: response.status,
    };
  }
  