import { FormGroup } from '@angular/forms';
import {
  UserMinimalDTO,
  convertResponseToUserMinimalDTO,
} from '../../../organization/users/dto/user.dto';
import {
  FileDTO,
  FileDetailsDTO,
  convertFormToFileFormDTOArray,
} from '../../../../shared/dto/file.dto';
import { ComplianceStatusUpdateFormDTO } from './compliance-status-update.dto';

export interface ComplianceStatusDTO {
  id: number;
  title_en: string;
  title_ar: string;
  color_code: string;
  type: string;
  status: string;
}

export interface ComplianceLatestStatusDTO {
  id: number;
  compliance_title_en: string;
  compliance_title_ar: string;
  compliance_description_en: string;
  compliance_description_ar: string;
  compliance_status_id: number;
  compliance_status_title_en: string;
  compliance_status_title_ar: string;
  compliance_status_color_code: string;
  comment: string;
  created_user_id: number;
  created_user_first_name_en: string;
  created_user_first_name_ar: string;
  created_user_last_name_en: string;
  created_user_last_name_ar: string;
  created_user_initial: string;
  created_user_color: string;
  created_user_email: string;
  created_user_profile_image_path: string;
  updated_user_id: number;
  updated_user_first_name_en: string;
  updated_user_first_name_ar: string;
  updated_user_last_name_en: string;
  updated_user_last_name_ar: string;
  updated_user_initial: string;
  updated_user_color: string;
  updated_user_email: string;
  updated_user_profile_image_path: string;
  created_at: string;
  updated_at: string | null;
  documents: FileDTO[];
}

export interface ComplianceLatestStatusDetailsDTO {
  id: number;
  compliance_title: string;
  compliance_description: string;
  compliance_status_id: number;
  compliance_status_title: string;
  compliance_status_color_code: string;
  comment: string;
  created_user: UserMinimalDTO;
  updated_user: UserMinimalDTO | null;
  created_at: string;
  updated_at: string | null;
  documents: FileDetailsDTO[];
}


export interface ComplianceStatusDetailsDTO {
  id: number;
  title: string;
  color_code: string;
  type: string;
  status: string;
}

export interface ComplianceStatusUpdateDTO {
  id: number;
  complianceRequirementTitle: string;
  comment: string;
  complianceStatusTitleId: number;
  complianceStatusTitleEn: string;
  complianceStatusTitleAr: string;
  complianceStatusColorCode: string;
  documents?: FileDTO;
  created_at: string;
  created_by: UserMinimalDTO;
  updated_at: string | null;
  updated_by: UserMinimalDTO | null;
}

export interface ComplianceStatusUpdateListDTO {
  id: number;
  requirementTitle: string;
  complianceStatusTitle: string;
  complianceStatusColor: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
  createdBy: UserMinimalDTO;
  updatedBy: UserMinimalDTO;
}

export interface ComplianceStatusUpdateDetailsDTO {
  id: number;
  comment: string;
  compliance_status: ComplianceStatusDetailsDTO | null;
  documents: FileDetailsDTO[];
  created_at: string;
  created_by: UserMinimalDTO;
  updated_at: string | null;
  updated_by: UserMinimalDTO | null;
}

export function convertResponseToComplianceStatusUpdateListDTO(
  response: any,
  lang: string
): ComplianceStatusUpdateListDTO[] {
  return response.map((item: any) => ({
    id: item.id,
    requirementTitle: item.compliance_requirement_title,
    complianceStatusTitle: item[`compliance_status_title_${lang}`],
    complianceStatusColor: item.compliance_status_color_code,
    comment: item.comment,
    createdAt: item.created_at,
    updatedAt: item.updated_at || '',
    createdBy: convertResponseToUserMinimalDTO(response, lang, 'created_user_'),
    updatedBy: convertResponseToUserMinimalDTO(response, lang, 'updated_user_'),
  }));
}

export function convertResponseToComplianceStatusUpdateForm(
  response: any
): any {
  return {
    id: response.id,
    ComplianceStatus: response.compliance_status?.id,
    Comment: response.comment,
    Documents: response.documents,
  };
}

export function convertResponseToComplianceStatusDetailsDTO(
  response: any,
  lang: string
): ComplianceStatusDetailsDTO | null {
  if (!response) {
    return null;
  }
  return {
    id: response.id,
    title: response[`title_${lang}`],
    color_code: response.color_code,
    type: response.type,
    status: response.status,
  };
}

export function convertFormToComplianceStatusUpdateFormDTO(
  formGroup: FormGroup
): ComplianceStatusUpdateFormDTO {
  return {
    id: formGroup.get('id')?.value,
    compliance_status_id: parseInt(
      formGroup.get('ComplianceStatus')?.value,
      10
    ),
    comment: formGroup.get('Comment')?.value,
    documents: convertFormToFileFormDTOArray(formGroup),
  };
}

export function convertResponseToComplinaceStatusUpdateDetailsDTO(
  response: any,
  lang: string
): ComplianceStatusUpdateDetailsDTO {
  return {
    id: response.id,
    comment: response.comment,
    compliance_status: convertResponseToComplianceStatusDetailsDTO(
      response.compliance_status,
      lang
    ),
    created_at: response.created_at,
    created_by: convertResponseToUserMinimalDTO(response.created_by, lang),
    documents: [] as FileDetailsDTO[],
    updated_by: response.updated_by
      ? convertResponseToUserMinimalDTO(response.updated_by, lang)
      : null,
    updated_at: response.updated_at,
  };
}

export function convertResponseToComplianceLatestStatusDetailsDTO(
  response: any,
  lang: string
): ComplianceLatestStatusDetailsDTO | null {

  if (!response) {
    return null;
  }
  return {
    id: response.id,
    compliance_title: response[`compliance_title_${lang}`],
    compliance_description: response[`compliance_description_${lang}`],
    compliance_status_id: response.compliance_status_id,
    compliance_status_title: response[`compliance_status_title_${lang}`],
    compliance_status_color_code: response.compliance_status_color_code,
    comment: response.comment,
    created_user : {
      id:response.created_user_id,
      first_name: response[`created_user_first_name_${lang}`],
      last_name: response[`created_user_last_name_${lang}`],
      initial: response.created_user_initial,
      color: response.created_user_color,
      profile_image_path: response.created_user_profile_image_path

    },
    updated_user : {
      id:response.updated_user_id,
      first_name: response[`updated_user_first_name_${lang}`],
      last_name: response[`updated_user_last_name_${lang}`],
      initial: response.updated_user_initial,
      color: response.updated_user_color,
      profile_image_path: response.updated_user_profile_image_path

    },
    created_at: response.created_at,
    updated_at: response.updated_at,
    documents: [] as FileDetailsDTO[],
  };
}
