import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import {
  ComplianceDetailsDTO,
  ComplianceFormDTO,
  ComplianceListDTO,
} from '../dto/compliance.dto';
import { convertResponseToComplianceCategoryDetailsDTO } from '../../../settings/compliance/dto/compliance-category.dto';
import { convertResponseToComplianceStatusDetailsDTO } from '../dto/compliance-status.dto';
import { convertResponseToUserMinimalDTO } from '../../../organization/users/dto/user.dto';
import { convertResponseToReviewFrequencyDTO } from '../../../settings/masters/dto/review-frequency.dto';
import { convertResponseToComplianceReviewStatusDetailsDTO } from '../../../settings/compliance/dto/compliance-review-status.dto';
import { convertResponseToSeverityLevelDetailsDTO } from '../../../settings/masters/dto/severity-level.dto';
import { convertResponseToLifeCycleStatusDetailsDTO } from '../../../settings/masters/dto/life-cycle-status.dto';
import { ReviewFrequencyConversion } from '../../../settings/masters/conversion/review-frequency.conversion';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { FileConversion } from '../../../../shared/conversion/file.converstion.dto';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ComplinaceConversion
  implements
    MainConversionInterface<
      ComplianceListDTO,
      ComplianceFormDTO,
      ComplianceDetailsDTO
    >
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private reviewFrequencyConv: ReviewFrequencyConversion,
    private tableConv: TableConversion,
    private userConv: UserConversion,
    private fileConv: FileConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToDetails(response: any): ComplianceDetailsDTO {
    throw new Error('Method not implemented.');
  }

  resToList(response: any[]): ComplianceListDTO[] {
    if (!response) {
      return [];
    }
    return response.map((item) => {
      return {
        id: item.id,
        reference_number: item.reference_number,
        standard_code: item.standard_code,
        title: item[`title_${this.lang}`],
        description: item[`description_${this.lang}`],
        version: item.version,
        deadline: item.deadline,
        issue_date: item.issue_date,
        expiry_date: item.expiry_date,
        next_review_date: item.next_review_date,
        last_reviewed_date: item.last_reviewed_date,
        responsible_user: this.userConv.resToUserMinimalDTO(
          item,
          'responsible_user'
        ),
        review_responsible_user: this.userConv.resToUserMinimalDTO(
          item,
          'review_responsible_user'
        ),
        responsible_unit_title: item[`responsible_unit_title_${this.lang}`],
        compliance_category_title:
          item[`compliance_category_title_${this.lang}`],
        review_frequency_title: item[`review_frequency_title_${this.lang}`],
        serverity_level: this.tableConv.resToColordItem(item, 'severity_level'),
        compliance_status: this.tableConv.resToColordItem(
          item,
          'compliance_status'
        ),
        created_at: item.created_at,
        created_by: convertResponseToUserMinimalDTO(item, this.lang),
        lifecycle_status: this.tableConv.resToColordItem(
          item,
          'lifecycle_status'
        ),
      };
    });
  }

  formGroupToForm(formGroup: FormGroup): ComplianceFormDTO {
    return {
      id: formGroup.get('id')?.value,
      compliance_category_id: formGroup.get('Category')?.value,
      compliance_status_id: formGroup.get('ComplianceStatus')?.value,
      title_en: formGroup.get('Title')?.value,
      title_ar: formGroup.get('Title')?.value,
      description_en: formGroup.get('Description')?.value,
      description_ar: formGroup.get('Description')?.value,
      severity_level_id: parseInt(formGroup.get('SeverityLevel')?.value, 10),
      review_frequency_id: formGroup.get('ReviewFrequency')?.value,
      // responsible_unit_id: formGroup.get('ResponsibleUnit')?.value,
      responsible_unit_id: 1, // TODO Data should be taken from form
      responsible_user_id: formGroup.get('ResponsibleUser')?.value,
      compliance_review_status_id: parseInt(
        formGroup.get('ComplianceReviewStatus')?.value,
        10
      ),
      review_responsible_user_id: formGroup.get('ReviewResponsibleUser')?.value,
      standard_code: formGroup.get('StandardCode')?.value,
      version: formGroup.get('Version')?.value,
      deadline: formGroup.get('Deadline')?.value,
      issue_date: formGroup.get('IssueDate')?.value,
      expiry_date: formGroup.get('ExpiryDate')?.value,
      next_review_date: formGroup.get('NextReviewDate')?.value,
      last_review_note: formGroup.get('LastReviewNotes')?.value,
      document: {
        id: formGroup.get('Document')?.value?.id,
        file_name: formGroup.get('Document')?.value?.file_name,
        original_name: formGroup.get('Document')?.value?.original_name,
        type: formGroup.get('Document')?.value?.type,
        size: formGroup.get('Document')?.value?.size,
        is_new_file: formGroup.get('Document')?.value?.is_new_file,
        language: 'en',
      },
      control_ids: formGroup.get('Controls')?.value,
    };
  }
  resToForm(response: any) {
    return {
      id: response.id,
      ReferenceNumber: response.reference_number,
      StandardCode: response.standard_code,
      Category: response.compliance_category?.id,
      ComplianceStatus: response.compliance_status?.id,
      Title: response.title_en,
      // TitleAr : response.title_ar,
      Description: response.description_en,
      // DescriptionAr : response.description_ar,
      ResponsibleUnit: response.responsible_unit?.id,
      ResponsibleUser: response.responsible_user?.id,
      ReviewFrequency: response.review_frequency?.id,
      ReviewResponsibleUser: response.review_responsible_user?.id,
      SeverityLevel: response.severity_level?.id,
      ComplianceReviewStatus: response.compliance_review_status?.id,
      Version: response.version,
      Deadline: response.deadline,
      IssueDate: response.issue_date,
      ExpiryDate: response.expiry_date,
      NextReviewDate: response.next_review_date,
      LastReviewNotes: response.last_review_note,
      Document: response.documents[0],
      DocumentLanguage: response.documents[0].language,
      Controls: response.controls.map((control: any) => control.control_id),
    };
  }

  resToComplianceDetailsDTO(response: any): ComplianceDetailsDTO {
    return {
      id: response.id,
      reference_number: response.reference_number,
      title: response.title_en,
      description: response.description_en,
      standard_code: response.standard_code,
      deadline: response.deadline,
      issue_date: response.issue_date,
      expiry_date: response.expiry_date,
      next_review_date: response.next_review_date,
      last_review_note: response.last_review_note,
      last_reviewed_date: response.last_reviewed_date,
      version: response.version,
      created_at: response.created_at,
      updated_at: response.updated_at ? response.updated_at : null,
      compliance_category: convertResponseToComplianceCategoryDetailsDTO(
        response.compliance_category,
        this.lang
      ),
      compliance_status: convertResponseToComplianceStatusDetailsDTO(
        response.compliance_status,
        this.lang
      ),
      responsible_unit: {
        id: response.responsible_unit.id,
        title: response.responsible_unit.title_en,
        status: response.responsible_unit.status,
      },
      responsible_user: response.responsible_user
        ? convertResponseToUserMinimalDTO(response.responsible_user, this.lang)
        : null,
      review_frequency: response.review_frequency
        ? convertResponseToReviewFrequencyDTO(
            response.review_frequency,
            this.lang
          )
        : null,
      compliance_review_status: response.compliance_review_status
        ? convertResponseToComplianceReviewStatusDetailsDTO(
            response.compliance_review_status,
            this.lang
          )
        : null,
      review_responsible_user: response.review_responsible_user
        ? convertResponseToUserMinimalDTO(
            response.review_responsible_user,
            this.lang
          )
        : null,
      last_reviewed_user: response.last_reviewed_user
        ? convertResponseToUserMinimalDTO(
            response.last_reviewed_user,
            this.lang
          )
        : null,
      severity_level: convertResponseToSeverityLevelDetailsDTO(
        response.severity_level,
        this.lang
      ),
      status: convertResponseToLifeCycleStatusDetailsDTO(
        response.status,
        this.lang
      ),
      document: this.fileConv.resToFileDetailsDTO(response.documents[0]),
      controls: [], //TODO
      incidents: [], //TODO
      created_by: convertResponseToUserMinimalDTO(
        response.created_by,
        this.lang
      ),
      updated_by: response.updated_by
        ? convertResponseToUserMinimalDTO(response.updated_by, this.lang)
        : null,
    };
  }
}
